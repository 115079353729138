body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: var(--bs-body-font-family);
}

:root {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-blue: #3f78e0;
  --bs-sky: #5eb9f0;
  --bs-purple: #747ed1;
  --bs-grape: #605dba;
  --bs-violet: #a07cc5;
  --bs-pink: #d16b86;
  --bs-fuchsia: #e668b3;
  --bs-red: #e2626b;
  --bs-orange: #f78b77;
  --bs-yellow: #fab758;
  --bs-green: #45c4a0;
  --bs-leaf: #7cb798;
  --bs-aqua: #54a8c7;
  --bs-navy: #343f52;
  --bs-ash: #9499a3;
  --bs-white: #fff;
  --bs-light: #fefefe;
  --bs-gray: #f6f7f9;
  --bs-dark: #262b32;
  --bs-primary: #2968b2;
  --bs-primary-dark: #2968b2;
  --bs-secondary: #ab3174;
  --bs-success: #45c4a0;
  --bs-info: #54a8c7;
  --bs-warning: #fab758;
  --bs-danger: #e2626b;
  --bs-blue-rgb: 63, 120, 224;
  --bs-sky-rgb: 94, 185, 240;
  --bs-purple-rgb: 116, 126, 209;
  --bs-grape-rgb: 96, 93, 186;
  --bs-violet-rgb: 160, 124, 197;
  --bs-pink-rgb: 209, 107, 134;
  --bs-fuchsia-rgb: 230, 104, 179;
  --bs-red-rgb: 226, 98, 107;
  --bs-orange-rgb: 247, 139, 119;
  --bs-yellow-rgb: 250, 183, 88;
  --bs-green-rgb: 69, 196, 160;
  --bs-leaf-rgb: 124, 183, 152;
  --bs-aqua-rgb: 84, 168, 199;
  --bs-navy-rgb: 52, 63, 82;
  --bs-ash-rgb: 148, 153, 163;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 254, 254, 254;
  --bs-gray-rgb: 246, 247, 249;
  --bs-dark-rgb: 38, 43, 50;
  --bs-primary-rgb: 63, 120, 224;
  --bs-secondary-rgb: 170, 176, 188;
  --bs-success-rgb: 69, 196, 160;
  --bs-info-rgb: 84, 168, 199;
  --bs-warning-rgb: 250, 183, 88;
  --bs-danger-rgb: 226, 98, 107;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 96, 105, 123;
  --bs-body-bg-rgb: 254, 254, 254;
  --bs-font-sans-serif: "Rubik", sans-serif;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-root-font-size: 20px;
  --bs-body-font-family: "Rubik", sans-serif;
  --bs-body-font-size: 0.8rem;
  --bs-body-font-weight: 500;
  --bs-body-line-height: 1.7;
  --bs-body-bg: #f4ebff;
}

.main-container {
  width: 500px;
  background-color: #fff;
  margin: auto;
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 1.45rem;
  }
}

.h2,
h2 {
  font-size: calc(1.255rem + 0.06vw);
}

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 1.3rem;
  }
}

.h3,
h3 {
  font-size: 1.1rem;
}

.h4,
h4 {
  font-size: 0.95rem;
}

.h5,
h5 {
  font-size: 0.9rem;
}

.h6,
h6 {
  font-size: 0.85rem;
}

p {
  margin-top: 0;
}
img,
svg {
  vertical-align: middle;
  max-width: 100%;
}

button,
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.fs-1 {
  font-size: 12px;
}

.fs-2 {
  font-size: 15px;
}

.fs-3 {
  font-size: 18px;
}

.fs-4 {
  font-size: 20px;
}

.fs-5 {
  font-size: 22px;
}

.fs-6 {
  font-size: 25px;
}

.fs-7 {
  font-size: 28px;
}

.fs-8 {
  font-size: 30px;
}

.fs-9 {
  font-size: 33px;
}

.fs-10 {
  font-size: 36px;
}

.straw-shape1 {
  width: 70px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 25%;
}
.straw-shape2 {
  width: 70px;
  height: 50px;
  position: absolute;
  bottom: 10%;
  left: 25%;
}

.straw-shape3 {
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 10%;
  right: 10%;
}

.straw-shape4 {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 20%;
}

.shape2 {
  position: absolute;
  top: 0;
  left: 50%;
}

.shape3 {
  position: absolute;
  bottom: 50%;
  right: 5%;
}

.shape4 {
  position: absolute;
  bottom: 10%;
  left: 0;
}

.shape5 {
  position: absolute;
  bottom: 10%;
  left: 50%;
}

.modal-section {
  display: flex;
}

.modal-section h1 {
  color: var(--bs-primary);
  font-weight: 900;
  font-family: var(--bs-body-font-family);
}

.modal-section p {
  color: var(--bs-primary);
  font-size: 16px;
  font-family: var(--bs-body-font-family);
}

.modal-header {
  border-bottom: none;
}

.modal-circle {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid var(--bs-primary);
}

.modal-circle::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid var(--bs-primary);
  top: 0;
  left: 170px;
  opacity: 0.4;
}

.logo {
  width: 170px;
}

.showcase-content h1 {
  font-size: 86px;
  color: var(--bs-primary);
  font-weight: 400;
  font-family: var(--bs-body-font-family);
}

.Thanks-content {
  font-size: 50px;
  color: var(--bs-primary);
  font-weight: 900;
  font-family: var(--bs-body-font-family);
}
.showcase-content p {
  font-size: 18px;
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
}

/* .showcase-circle {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-image: url("./assets/honey.png");
  background-size: cover;
} */

.honey-pic {
  width: 270px;
  height: 270px;
}

.showcase-btn {
  background-color: var(--bs-primary);
  color: var(--bs-body-bg);
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 18px;
  font-family: var(--bs-body-font-family);
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.showcase-btn:hover,
.showcase-btn:focus,
.showcase-btn:active {
  background-color: var(--bs-primary-dark);
  border: none;
  box-shadow: none;
}

.card-btn {
  background-color: transparent;
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
  outline: none;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 16px;
  font-family: var(--bs-body-font-family);
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.card-btn:hover,
.card-btn:focus,
.card-btn:active {
  background-color: var(--bs-primary) !important;
  border: none;
  box-shadow: none;
}

.points-section {
  background-color: #ffc4c4;
  border-radius: 50px;
  padding: 20px 50px;
}

.points-section h1 {
  color: var(--bs-primary);
  font-size: 22px;
  font-family: var(--bs-body-font-family);
  margin-top: 10px;
}

.points-section i {
  font-size: 30px;
  color: var(--bs-primary);
}

.border-after {
  position: relative;
}

.border-after::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid var(--bs-primary);
  top: 0;
  left: 350px;
  opacity: 0.4;
}

/* timeline css */
.ag-format-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  max-width: 1160px;
  margin-right: auto;
  margin-left: auto;

  position: relative;
}

.ag-timeline-block {
  padding: 10px 0;
}
.ag-timeline_title-box {
  padding: 0 0 30px;

  text-align: center;
}
.ag-timeline_tagline {
  font-size: 40px;
  color: rgb(84, 89, 95);
}
.ag-timeline_title {
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
  font-size: 25px;
  margin-top: 1.5rem;
}

.ag-timeline_title-para {
  font-size: 18px;
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
  width: 60%;
  margin: auto;
}

.ag-timeline_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  margin: 0 0 130px 0;

  position: relative;
}
.ag-timeline_item:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.ag-timeline {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  position: relative;
}
.ag-timeline_line {
  width: 8px;
  background-color: #d16b47;

  position: absolute;
  top: 0;
  left: 50%;
  bottom: 200px;

  overflow: hidden;

  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ag-timeline_line-progress {
  width: 100%;
  height: 20%;
  background-color: var(--bs-primary);
  opacity: 0;
}
.ag-timeline-card_point-box {
  margin-left: 20px;
  margin-right: 20px;
}
.ag-timeline-card_point {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
  line-height: 30px;
  width: 230px;
  /* border: 3px solid var(--bs-primary); */
  background-color: #d16b47;

  text-align: center;
  font-family: "ESL Legend", sans-serif;
  font-size: 20px;
  color: #fff;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.js-ag-active .ag-timeline-card_point {
  color: #1d1d1b;
  background-color: var(--bs-primary);
}

.ag-timeline-card_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  height: 100%;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;

  background-color: #fff;

  opacity: 1;

  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;

  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  -o-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);

  animation-fill-mode: both;

  position: relative;
}
.js-ag-animated .ag-timeline-card_item {
  opacity: 0;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.js-ag-animated.js-ag-show:nth-child(2n + 1) .ag-timeline-card_item {
  -webkit-animation-name: card-animation-left;
  -moz-animation-name: card-animation-left;
  -o-animation-name: card-animation-left;
  animation-name: card-animation-left;
}
.js-ag-animated.js-ag-show:nth-child(2n) .ag-timeline-card_item {
  -webkit-animation-name: card-animation-right;
  -moz-animation-name: card-animation-right;
  -o-animation-name: card-animation-right;
  animation-name: card-animation-right;
}

.ag-timeline-card_arrow {
  height: 18px;
  width: 18px;
  margin-top: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* .ag-timeline_item:nth-child(2n + 1) .ag-timeline-card_arrow {
  margin-left: calc(-18px / 2);
  margin-right: calc(-18px / 2);
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_arrow {
  margin-left: -10px;

  right: auto;
  left: 0;
} */

.ag-timeline-card_img {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  object-fit: cover;
}

.ag-timeline-card_img-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ag-timeline-card_meta-box {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_meta-box {
  text-align: right;
}
.ag-timeline-card_meta {
  margin: 10px 0 0;
  font-size: 28px;
  font-weight: 800;
  color: var(--bs-primary);
}
.ag-timeline-card_info {
  padding: 20px 30px;
}
.ag-timeline-card_title {
  display: none;
  margin: 10px 0 0;
  font-weight: bold;
  font-size: 28px;
  color: var(--bs-primary);
}
.ag-timeline-card_desc {
  line-height: 1.45;

  font-size: 16px;
  color: var(--bs-primary-dark);
}

@-webkit-keyframes card-animation-left {
  0% {
    opacity: 0;

    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes card-animation-left {
  0% {
    opacity: 0;

    -moz-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes card-animation-left {
  0% {
    opacity: 0;

    -o-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes card-animation-left {
  0% {
    opacity: 0;

    -webkit-transform: translateX(-400px);
    -moz-transform: translateX(-400px);
    -o-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes card-animation-right {
  0% {
    opacity: 0;

    -webkit-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes card-animation-right {
  0% {
    opacity: 0;

    -moz-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes card-animation-right {
  0% {
    opacity: 0;

    -o-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes card-animation-right {
  0% {
    opacity: 0;

    -webkit-transform: translateX(400px);
    -moz-transform: translateX(400px);
    -o-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
/* timeline end */

.flowers {
  margin-top: -4rem;
}

.social-section {
  display: flex;
  justify-content: center;
}

.social-section i {
  font-size: 25px;
  color: var(--bs-primary);
  margin-left: 1rem;
}

.powered-footer {
  margin: 10px 0 0;
  font-weight: bold;
  font-size: 14px;
  color: var(--bs-primary);
}

.footer-logo {
  width: 130px;
}

@media only screen and (max-width: 979px) {
  .points-section {
    width: 96%;
  }

  .Thanks-content {
    font-size: 35px;
  }

  .points-section .col-lg-4 {
    margin-bottom: 1rem;
  }

  .border-after::after {
    display: none;
  }

  .modal-circle {
    margin-bottom: 1rem;
  }

  .modal-circle::after {
    display: none;
  }

  .ag-timeline_line {
    top: 20px !important;
    left: 100px;
    bottom: 270px;
  }
  .ag-timeline-card_point {
    height: 200px;
    line-height: 40px;
    width: 200px;
  }

  .ag-timeline-card_img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  .ag-timeline_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0 0 60px 0;
    position: relative;
  }
  .ag-timeline-card_title {
    display: block;
  }

  .ag-timeline-card_meta-box {
    display: none;
  }
  .ag-timeline-card_point-box {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;

    margin-left: 8px;
  }

  .ag-timeline-card_item {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;

    max-width: 100%;
    margin-top: 220px;
    margin-left: -100px;
    margin-right: 1rem;
  }
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .js-ag-animated.js-ag-show:nth-child(2n) .ag-timeline-card_item,
  .js-ag-animated.js-ag-show:nth-child(2n + 1) .ag-timeline-card_item {
    -webkit-animation-name: card-animation-right;
    -moz-animation-name: card-animation-right;
    -o-animation-name: card-animation-right;
    animation-name: card-animation-right;
  }
  .ag-timeline-card_arrow {
    margin-left: -10px;
    right: auto;
    left: 20px;
    top: -30px;
  }
  .ag-timeline-card_arrow {
    margin-left: -10px;
    right: auto;
    left: 20px;
    top: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}

@media only screen and (max-width: 639px) {
  .ag-timeline_title {
    font-size: 18px;
    width: 65%;
    margin: auto;
  }

  .Thanks-content {
    font-size: 20px !important;
  }

  .showcase-content p {
    font-size: 16px !important;
    width: 90%;
    margin: 1rem auto !important;
  }

  .showcase-circle {
    margin: 2rem auto !important;
    width: 250px !important;
    height: 250px !important;
  }

  .logo {
    width: 120px;
  }

  .showcase-content h1 {
    font-size: 25px !important;
  }

  .subtitle {
    font-size: 20px;
  }

  .ag-timeline-card_info {
    padding: 20px 15px;
  }
  .ag-timeline-card_desc {
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .ag-timeline_line {
    bottom: 300px;
  }
}

@media only screen and (max-width: 250px) {
  .ag-timeline_line {
    bottom: 350px !important;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }
}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }
}
@media (min-width: 1000px) and (max-width: 1430px) {
  .shape3 {
    display: none;
  }
  .showcase-circle {
    margin-left: 2rem !important;
  }
}
@media (max-width: 376px) {
  .straw-shape3 {
    display: none !important;
  }
  .shape4 {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .showcase-content h1 {
    font-size: 44px;
  }

  .showcase-circle {
    margin: 3rem auto;
    width: 300px;
    height: 300px;
  }
  .ag-timeline-card_meta {
    font-size: 17px !important;
  }
  .straw-shape2 {
    display: none;
  }
  .straw-shape3 {
    display: none;
  }

  .shape4 {
    display: none;
  }

  .straw-shape4 {
    right: 5%;
  }
  .shape5 {
    display: none;
  }
  .shape3 {
    display: none;
  }
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 1.45rem;
  }
}

.h2,
h2 {
  font-size: calc(1.255rem + 0.06vw);
}

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 1.3rem;
  }
}

.h3,
h3 {
  font-size: 1.1rem;
}

.h4,
h4 {
  font-size: 0.95rem;
}

.h5,
h5 {
  font-size: 0.9rem;
}

.h6,
h6 {
  font-size: 0.85rem;
}

p {
  margin-top: 0;
}
img,
svg {
  vertical-align: middle;
  max-width: 100%;
}

button,
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.fs-1 {
  font-size: 12px;
}

.fs-2 {
  font-size: 15px;
}

.fs-3 {
  font-size: 18px;
}

.fs-4 {
  font-size: 20px;
}

.fs-5 {
  font-size: 22px;
}

.fs-6 {
  font-size: 25px;
}

.fs-7 {
  font-size: 28px;
}

.fs-8 {
  font-size: 30px;
}

.fs-9 {
  font-size: 33px;
}

.fs-10 {
  font-size: 36px;
}

.straw-shape1 {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 25%;
}
.straw-shape2 {
  width: 70px;
  height: 50px;
  position: absolute;
  bottom: 10%;
  left: 25%;
}

.straw-shape3 {
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 10%;
  right: 10%;
}

.straw-shape4 {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 8%;
}

.shape2 {
  position: absolute;
  top: 0;
  left: 50%;
}

.shape3 {
  position: absolute;
  bottom: 50%;
  right: 5%;
}

.shape4 {
  position: absolute;
  bottom: 10%;
  left: 0;
}

.shape5 {
  position: absolute;
  bottom: 10%;
  left: 50%;
}

.modal-section {
  display: flex;
}

.modal-section h1 {
  color: var(--bs-primary);
  font-weight: 900;
  font-family: var(--bs-body-font-family);
}

.modal-section p {
  color: var(--bs-primary);
  font-size: 16px;
  font-family: var(--bs-body-font-family);
}

.modal-header {
  border-bottom: none;
}

.modal-circle {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid var(--bs-primary);
}

.modal-circle::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid var(--bs-primary);
  top: 0;
  left: 170px;
  opacity: 0.4;
}

.logo {
  width: 170px;
}

.showcase-content h1 {
  font-size: 86px;
  color: var(--bs-primary);
  font-weight: 400;
  font-family: var(--bs-body-font-family);
}

.showcase-content input {
  color: #898989;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

input:active,
input:focus input:hover {
  border: 1px solid var(--bs-primary) !important;
}

.Thanks-content {
  font-size: 50px;
  color: var(--bs-primary);
  font-weight: 900;
  font-family: var(--bs-body-font-family);
}
.showcase-content p {
  font-size: 18px;
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
}

/* .showcase-circle {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  border: 10px solid var(--bs-primary);
  background-image: url("./assets/straw-img.jpg");
  background-size: cover;
} */

.showcase-btn {
  background-color: var(--bs-primary);
  color: var(--bs-body-bg);
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 14px;
  font-family: var(--bs-body-font-family);
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.showcase-btn:hover,
.showcase-btn:focus,
.showcase-btn:active {
  background-color: var(--bs-primary-dark);
  border: none;
  box-shadow: none;
}

.card-btn {
  background-color: transparent;
  border: 1px solid var(--bs-secondary);
  color: var(--bs-secondary);
  outline: none;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 16px;
  font-family: var(--bs-body-font-family);
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.card-btn:hover,
.card-btn:focus,
.card-btn:active {
  background-color: var(--bs-secondary) !important;
  border: none;
  box-shadow: none;
}

.points-section {
  background-color: #ffc4c4;
  border-radius: 50px;
  padding: 20px 50px;
}

.points-section h1 {
  color: var(--bs-primary);
  font-size: 22px;
  font-family: var(--bs-body-font-family);
  margin-top: 10px;
}

.points-section i {
  font-size: 30px;
  color: var(--bs-primary);
}

.border-after {
  position: relative;
}

.border-after::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid var(--bs-primary);
  top: 0;
  left: 350px;
  opacity: 0.4;
}

/* timeline css */
.ag-format-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  max-width: 1160px;
  margin-right: auto;
  margin-left: auto;

  position: relative;
}

.ag-timeline-block {
  padding: 10px 0;
  background-color: rgba(115, 102, 255, 0.12);
}
.ag-timeline_title-box {
  padding: 0 0 30px;

  text-align: center;
}
.ag-timeline_tagline {
  font-size: 40px;
  color: rgb(84, 89, 95);
}
.ag-timeline_title {
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
  font-size: 25px;
  margin-top: 1rem;
}

.ag-timeline_title-para {
  font-size: 18px;
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
  width: 60%;
  margin: auto;
}

.ag-timeline_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  margin: 0 0 130px 0;

  position: relative;
}
.ag-timeline_item:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.ag-timeline {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  position: relative;
}
.ag-timeline_line {
  width: 8px;
  background: var(--bs-primary);

  position: absolute;
  top: 0;
  left: 50%;
  bottom: 200px;

  overflow: hidden;

  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ag-timeline_line-progress {
  width: 100%;
  height: 20%;
  background: linear-gradient(
    90deg,
    rgba(41, 104, 178, 1) 0%,
    rgba(72, 102, 176, 1) 35%,
    rgba(171, 49, 116, 1) 100%
  );
  opacity: 0;
}
.ag-timeline-card_point-box {
  margin-left: 20px;
  margin-right: 20px;
}
.ag-timeline-card_point {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
  line-height: 30px;
  width: 230px;
  /* border: 3px solid var(--bs-primary); */
  /* background: rgb(41, 104, 178); */
  background: linear-gradient(
    90deg,
    rgba(41, 104, 178, 1) 0%,
    rgba(72, 102, 176, 1) 35%,
    rgba(171, 49, 116, 1) 100%
  );

  text-align: center;
  font-family: "ESL Legend", sans-serif;
  font-size: 20px;
  color: #fff;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.js-ag-active .ag-timeline-card_point {
  color: #1d1d1b;
  background-color: var(--bs-primary);
}

.ag-timeline-card_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  height: 100%;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;

  background-color: #fff;

  opacity: 1;

  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;

  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  -o-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);

  animation-fill-mode: both;

  position: relative;
}
.js-ag-animated .ag-timeline-card_item {
  opacity: 0;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.js-ag-animated.js-ag-show:nth-child(2n + 1) .ag-timeline-card_item {
  -webkit-animation-name: card-animation-left;
  -moz-animation-name: card-animation-left;
  -o-animation-name: card-animation-left;
  animation-name: card-animation-left;
}
.js-ag-animated.js-ag-show:nth-child(2n) .ag-timeline-card_item {
  -webkit-animation-name: card-animation-right;
  -moz-animation-name: card-animation-right;
  -o-animation-name: card-animation-right;
  animation-name: card-animation-right;
}

.ag-timeline-card_arrow {
  height: 18px;
  width: 18px;
  margin-top: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ag-timeline-card_arrow {
  margin-left: calc(-18px / 2);
  margin-right: calc(-18px / 2);
}
.ag-timeline-card_arrow {
  margin-left: -10px;

  right: auto;
  left: 0;
}

.ag-timeline-card_img {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  object-fit: cover;
}

.ag-timeline-card_img-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ag-timeline-card_meta-box {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_meta-box {
  text-align: right;
}
.ag-timeline-card_meta {
  margin: 10px 0 0;
  font-size: 20px;
  font-weight: 700;
  color: var(--bs-primary);
}
.ag-timeline-card_info {
  padding: 20px 30px;
}
.ag-timeline-card_title {
  display: none;
  margin: 10px 0 0;
  font-weight: bold;
  font-size: 28px;
  color: var(--bs-primary);
}
.ag-timeline-card_desc {
  line-height: 1.45;

  font-size: 16px;
  color: var(--bs-primary);
}

@-webkit-keyframes card-animation-left {
  0% {
    opacity: 0;

    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes card-animation-left {
  0% {
    opacity: 0;

    -moz-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes card-animation-left {
  0% {
    opacity: 0;

    -o-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes card-animation-left {
  0% {
    opacity: 0;

    -webkit-transform: translateX(-400px);
    -moz-transform: translateX(-400px);
    -o-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes card-animation-right {
  0% {
    opacity: 0;

    -webkit-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes card-animation-right {
  0% {
    opacity: 0;

    -moz-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes card-animation-right {
  0% {
    opacity: 0;

    -o-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes card-animation-right {
  0% {
    opacity: 0;

    -webkit-transform: translateX(400px);
    -moz-transform: translateX(400px);
    -o-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
/* timeline end */

.social-section {
  display: flex;
  justify-content: center;
}

.social-section i {
  font-size: 25px;
  color: var(--bs-primary);
  margin-left: 1rem;
}

.powered-footer {
  margin: 10px 0 0;
  font-weight: bold;
  font-size: 14px;
  color: var(--bs-primary);
}

.footer-logo {
  width: 130px;
}

@media only screen and (max-width: 5000px) {
  .points-section {
    width: 96%;
  }

  .Thanks-content {
    font-size: 35px;
  }

  .points-section .col-lg-4 {
    margin-bottom: 1rem;
  }

  .border-after::after {
    display: none;
  }

  .modal-circle {
    margin-bottom: 1rem;
  }

  .modal-circle::after {
    display: none;
  }

  .ag-timeline_line {
    top: 20px !important;
    left: 100px;
    bottom: 270px;
  }
  .ag-timeline-card_point {
    height: 200px;
    line-height: 40px;
    width: 200px;
  }

  .ag-timeline-card_img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  .ag-timeline_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0 0 60px 0;
    position: relative;
  }
  .ag-timeline-card_title {
    display: block;
  }

  .ag-timeline-card_meta-box {
    display: none;
  }
  .ag-timeline-card_point-box {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;

    margin-left: 8px;
  }

  .ag-timeline-card_item {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;

    max-width: 100%;
    margin-top: 220px;
    margin-left: -100px;
    margin-right: 1rem;
  }
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .js-ag-animated.js-ag-show:nth-child(2n) .ag-timeline-card_item,
  .js-ag-animated.js-ag-show:nth-child(2n + 1) .ag-timeline-card_item {
    -webkit-animation-name: card-animation-right;
    -moz-animation-name: card-animation-right;
    -o-animation-name: card-animation-right;
    animation-name: card-animation-right;
  }
  .ag-timeline-card_arrow {
    margin-left: -10px;
    right: auto;
    left: 20px;
    top: -30px;
  }
  .ag-timeline-card_arrow {
    margin-left: -10px;
    right: auto;
    left: 20px;
    top: -30px;
  }
}

@media only screen and (max-width: 5000px) {
  .ag-format-container {
    width: 96%;
  }
}

@media only screen and (max-width: 5000px) {
  .ag-timeline_title {
    font-size: 20px;
    width: 65%;
    margin: 1rem auto;
  }

  .Thanks-content {
    font-size: 20px !important;
  }

  .showcase-content p {
    font-size: 22px !important;
    width: max-content;
    padding: 2px 15px;
    margin: 1rem auto !important;
    background: #ffcc00;
    color: var(--bs-secondary);
    border-radius: 25px;
  }

  .showcase-circle {
    margin: 2rem auto !important;
    width: 250px !important;
    height: 250px !important;
  }

  .showcase-button {
    padding: 5px 10px !important;
  }

  .logo {
    width: 170px;
  }

  .showcase-content h1 {
    font-size: 25px !important;
    font-family: "Rubik", sans-serif;
  }

  .subtitle {
    font-size: 17px;
  }

  .ag-timeline-card_info {
    padding: 20px 15px;
  }
  .ag-timeline-card_desc {
    font-size: 16px;
  }
}

@media only screen and (max-width: 5000px) {
  .ag-timeline_line {
    bottom: 450px;
  }
}

@media only screen and (max-width: 250px) {
  .ag-timeline_line {
    bottom: 350px !important;
  }
}

@media (min-width: 768px) {
  .straw-shape1 {
    display: none !important;
  }
  .straw-shape2 {
    display: none !important;
  }
  .straw-shape3 {
    display: none !important;
  }
  .straw-shape4 {
    display: none !important;
  }
  .shape {
    display: none !important;
  }

  .shape2 {
    display: none !important;
  }
  .shape3 {
    display: none !important;
  }

  .shape4 {
    display: none !important;
  }
  .shape5 {
    display: none !important;
  }
}

/* @media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }
} */

/* @media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }
} */
/* @media (min-width: 1000px) and (max-width: 1430px) {
  .shape3 {
    display: none;
  }
  .showcase-circle {
    margin-left: 2rem !important;
  }
} */
@media (max-width: 768px) {
  .main-container {
    width: auto !important;
  }
}

/* @media (max-width: 992px) {
  .showcase-content h1 {
    font-size: 44px;
  }

  .showcase-circle {
    margin: 3rem auto;
    width: 300px;
    height: 300px;
  }
  .ag-timeline-card_meta {
    font-size: 17px;
  }
  .straw-shape2 {
    display: none;
  }
  .straw-shape3 {
    display: none;
  }

  .shape4 {
    display: none;
  }

  .straw-shape4 {
    right: 5%;
  }
  .shape5 {
    display: none;
  }
  .shape3 {
    display: none;
  }
} */
