.farmers-banner {
  width: 100%;
  height: 100%;
}

.header {
  background: url("../strawberry-farm.jpg");
  text-align: center;
  width: 100%;
  height: 500px;
  background-size: cover;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 85% 85% / 30%;
}
.header .overlay {
  width: 100%;
  height: 100%;
  padding: 50px;
  color: #fff;
}

.main-section {
  margin-bottom: 1rem;
}

.main-section h1 {
  font-size: 50px;
  color: var(--bs-primary);
  font-weight: 900;
  font-family: var(--bs-body-font-family);
  margin-top: 3rem;
}

.main-section p {
  font-size: 18px;
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
  width: 70%;
  margin: 0 auto;
}

.farmer-card {
  max-width: 300px;
  height: max-content;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  margin: 0 auto;
}

.farmer-card-img-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid var(--bs-primary);
  margin: 0 auto;
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}

.farmer-card-content h1 {
  font-size: 22px;
  color: var(--bs-primary);
  font-weight: 900;
  font-family: var(--bs-body-font-family);
}

.farmer-card-content p {
  font-size: 16px;
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
}

@media (max-width: 5000px) {
  .header-rounded {
    left: -14%;
    width: 200%;
    height: 80vh;
    overflow: hidden;
  }
  .header {
    height: 300px;
  }
}

@media (max-width: 5000px) {
  .main-section h1 {
    font-size: 35px;
  }
  .farmer-card-content h1 {
    font-size: 20px !important;
  }
  .farmer-card {
    max-width: 280px;
  }
}
